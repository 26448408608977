.tracker__main {
  display: flex;
  flex-direction: column;
}
.loading__container {
  display: grid;
  place-items: center;
}
.loading__container__child {
  width: 120px;
  height: 20px;
  background: radial-gradient(circle 10px, #000 95%, #0000) right /
      calc(200% + 20px) 100%,
    linear-gradient(#000 0 0) center/100% 6px;
  background-repeat: no-repeat;
  -webkit-mask: radial-gradient(circle 4px, #0000 93%, #000)
    right/calc(200% + 20px) 100%;
  animation: ct6 1s infinite linear;
}

@keyframes ct6 {
  100% {
    background-position: left;
    -webkit-mask-position: left;
  }
}

.infinity-2 {
  width: 90px;
  height: 14px;
  background: radial-gradient(circle closest-side, currentColor 92%, #0000)
    calc(100% / 3) 0 / calc(100% / 4) 100%;
  animation: i2 0.5s infinite linear;
}
@keyframes i2 {
  100% {
    background-position: 0 0;
  }
}
